<template>
    <div class="row overflow-hidden">
        <div class="col-12 col-xl-5 mb-4 mb-xl-0">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3 class="m-0">Latest News</h3>
                <router-link to="/all-news" class="linkBtn d-flex align-items-center">See All <vue-feather size="12px" type="chevron-right" class="chevron-right"></vue-feather></router-link>
            </div>
            <div class="mobScrollable" v-if="pageWiseNews.totalRecords && pageWiseNews.totalRecords > 0" :style="`height: ${divHeight}px`">
                <div class="news pb-1 mb-2" :class="[{'border-bottom': key !=3}]" v-for="(item, key) in pageWiseNews.records.slice(0, 7)" :key="key" :id="'news'+item._id">
                    <h6 @click="goToDetailPage(item)" class="mb-0"><a href="javascript:void(0);">{{item.title ? item.title.slice(0, 50) : ""}}</a></h6>
                    <p class="txt mb-1" v-html="item.description.slice(0, 90)"></p>
                    <p class="clock f-12 mb-1"><vue-feather type="clock" class="clock-icon"></vue-feather>{{formatNow(item.published,'now',0)}}</p>
                </div>
            </div>
            <no-record v-else></no-record>
        </div>
        <div class="col-12 col-xl-7" ref="calendar">
            <div class="d-flex justify-content-between align-items-center mb-4">
                <h3 class="m-0">Economic Calendar</h3>
                <router-link to="/economic-calendar" class="linkBtn d-flex align-items-center">See All <vue-feather size="12px" type="chevron-right" class="chevron-right"></vue-feather></router-link>
            </div>
            <div v-if="economicData.length" class="table-responsive economic">
                <table v-for="(row, indexOuter) of economicData" class="table table-sm border-0 bg-white" :class="{'border-top': indexOuter > 0}" :key="row.date">
                    <thead>
                        <tr role="button" @click="()=> toggleOuter(indexOuter)">
                            <th class="date medium">{{COMMON.formatDate(row.date, "Do MMMM")}}</th>
                            <th colspan="3" class="events text-end">{{row.items.length}}events</th>
                        </tr>
                    </thead>
                    <tbody v-if="activeOuter==indexOuter">
                        <tr
                            role="button"
                            v-for="(event, indexInner) of rowInnerList"
                            @click="()=> toggleInner(indexInner, event)"
                            class="borderAdd"
                            :class="{active: activeInner==indexInner, rt: event.importance==1, et: event.importance==2, bt: event.importance==3,}"
                            :key="event._id"
                        >
                            <td class="p-0 border-0" colspan="4">
                                <table width="100%" class="fixed-layout nowrap">
                                    <tr>
                                        <td :class="{'active-td': activeInner==indexInner}">
                                            <span class="d-flex align-items-center">
                                                <img width="25" height="25" :src=" 'assets/images/country_flag/' + event.country.toLowerCase() + '.webp' " class="rounded-circle me-2" alt="" /><span class="ellipsis">{{event.country}}</span>
                                            </span>
                                        </td>
                                        <td>{{COMMON.formatDate(event.date, "hh:mm a")}}</td>
                                        <td>{{event.event}}</td>
                                        <td>
                                            <a class="d-block text-end" href="javascript:void(0);">
                                                <vue-feather v-if="activeInner !=indexInner" type="chevron-down"></vue-feather> <vue-feather v-if="activeInner==indexInner" type="chevron-up"></vue-feather>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr class="subactive-tr" v-if="activeInner==indexInner">
                                        <td colspan="4">
                                            <div class="d-flex align-items-center justify-content-between openDom">
                                                <span class="subactive-td">
                                                    <div class="f-16">{{COMMON.formatCalendarDate( activeEvent.date, "duration")}}</div>
                                                    <div class="f-12">Time{{COMMON.formatDate( activeEvent.date, "HH:mm")}}</div>
                                                </span>
                                                <span align="center" class="active-time-int middle">Actual{{activeEvent.actual ? activeEvent.actual : "-"}}</span>
                                                <span colspan="2" class="subactive-td2">
                                                    <div class="forecast"><span class="f-12 me-2">Forecast</span> <span class="f-16">{{activeEvent.teForecast ? activeEvent.teForecast : "-"}}</span></div>
                                                    <div class="previe"><span class="f-12 me-2">Previous</span> <span class="f-16">{{activeEvent.previous ? activeEvent.previous : "-"}}</span></div>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <no-record v-else></no-record>
        </div>
       <!--  <div class="modal fade ideaModal" v-if="Object.keys(showNews).length > 0" :class="Object.keys(showNews).length > 0 ? 'show' : ''">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="row modal-content g-0 h-100">
                    <div class="col-12 col-lg-6 ideaChart"><img class="d-block w-100 h-100" :src="showNews.image" alt="News" title="News Image" /></div>
                    <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                        <div class="modal-body p-4 pt-0 border-0">
                            <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" class="d-flex"><vue-feather size="23px" type="printer"></vue-feather></a>
                                    </li>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showNews={}"></button>
                            </div>
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4"><a class="green" href="portfolios.com">{{showNews.source}}</a></li>
                                <li>{{formatNow(showNews.published,'now',0)}}</li>
                            </ul>
                            <h2 class="medium my-4 py-2">{{showNews.title}}</h2>
                            <p v-html="showNews.description"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>


<script>
import { useMainStore } from "@/store";
import moment from "moment";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  props : ['page'],
  data() {
    return {
      showNews: {},
      activeOuter: 0,
      activeInner: 0,
      // activeEvent: {},
      divHeight : 100,
    };
  },
  watch: {
    "store.calendarData"(val) {
      this.divHeight = this.$refs.calendar.clientHeight;
    },
  },
  computed: {
    pageWiseNews(){
        if(this.page == 'instrument'){
            return this.store.instrumentNews
        }else{
            return this.store.newsList
        }
    },
    dateAdjustedEvents() {
      if (Object.keys(this.store.calendarData).length) {
        let temp = [],
          allEvents = [];
        for (let key in this.store.calendarData) {
          this.store.calendarData[key].items.map((i) => {
            let obj = Object.assign({}, i);
            obj.date = this.COMMON.formatCalendarDate(
              obj.date,
              "YYYY-MM-DD HH:mm",
              0
            );
            //console.log(obj);
            temp.push(obj);
          });
        }
        temp.map((i, index) => {
          const ind = allEvents.findIndex(
            (j) =>
              moment(j.date).format("YYYY-MM-DD") ==
              moment(i.date).format("YYYY-MM-DD")
          );
          if (ind > -1) {
            if (allEvents[ind].items) {
              allEvents[ind].items.push(i);
            } else {
              allEvents[ind].items = [i];
            }
          } else {
            allEvents.push({
              date: moment(i.date).format("YYYY-MM-DD"),
              items: [i],
            });
          }
        });
        return allEvents;
      } else {
        return [];
      }
    },
    economicData() {
      if (this.dateAdjustedEvents.length) {
        let arr = [];
        this.dateAdjustedEvents.map((i) => {
          let temp = {
            date: i.date,
            items: [],
          };
          if (i.items.length) {
            temp.items = i.items.filter((j) => {
              return (
                moment(
                  this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                ).diff(moment(), "minute") > 5
              );
            });
          }
          if (temp.items.length) arr.push(temp);
        });
        return arr.slice(0, 5);
        // return arr;
      } else {
        return [];
      }
    },
    rowInnerList() {
      if (
        this.activeOuter > -1 &&
        this.economicData[this.activeOuter].items &&
        this.economicData[this.activeOuter].items.length
      ) {
        let arr = [];

        arr = this.economicData[this.activeOuter].items.filter((j) => {
          return (
            j.importance > 1 &&
            moment(
              this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
            ).diff(moment(), "minute") > 5
          );
        });
        return arr.slice(0, 5);
      } else {
        return [];
      }
    },
    activeEvent() {
      if (this.activeOuter > -1 && this.activeInner > -1)
        return this.rowInnerList[this.activeInner];
    },
  },
  methods: {
    toDivElement(id){
        try{
            setTimeout(function(){
                var elmnt = document.getElementById(id);
                elmnt.scrollIntoView({behavior: "smooth"});
                document.getElementById(id).focus();
                //var top = $(window).scrollTop()
                // console.log(id,'top',top)
                // $(window).scrollTop(top-80)
            }, 500);
        }catch(e){}
    },
    goToDetailPage(item){
        let title = item.title
        if(title.includes('-')){
            title = title.replace(/-+/g, '')
        }else{
            let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
            if(titl.includes('--')){
                title = titl.replace(/--+/g, '-')
            }else{
                title = item.title
            }
        }
        this.$router.push({
            name: 'news_details',
            params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
        });
    },
    formatNow(date,format,offset){
        if(!date) return 'N/A'
        let d = moment(`${date.split(' ')[0]} ${date.split(' ')[1]}`, 'YYYY-MM-DD HH:mm:ss')
        let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
        d.add(tz, 'minutes')
        return d.fromNow()
    },
    calnewsDetail(){
        let form ={
            '_id' : this.showNews._id
        }
        this.store
      .callNewsDetails(form, false)
      .then((response) => {
      })
      .catch();
    },
    toggleOuter(index) {
      if (this.activeOuter == index) {
        // this.activeOuter = -1;
        // this.activeInner = -1;
      } else {
        this.activeOuter = index;
        this.activeInner = -1;
      }
    },
    toggleInner(index, event) {
      if (this.activeInner == index) {
        this.activeInner = -1;
        // this.activeEvent = {};
      } else {
        this.activeInner = index;
        // this.activeEvent = event;
      }
    },
  },
  created() {
    // this.firstNews = this.store.newsList[0] || {}
    let formData = {
      // filter: { priority: 3 },
    };
    let calendarFormData = {
      filter: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().add(12, "days").format("YYYY-MM-DD"),
      },
    };
    if (this.$route.query && this.$route.query.market) {
      formData.tags = [this.$route.query.market];
    }
    if(this.page && this.page == 'instrument'){
        if(this.$route.query.type == 'currencies'){
             formData['type'] = 'forex'
        }else if(this.$route.query.type == 'INDICIES'){
             formData['type'] = 'INDICES'
        }else{
            formData['type'] = this.$route.query.type
        }
    }
    this.store
      .callNewsList(formData, false)
      .then(() => {
        // this.firstNews = response[0]
      })
      .catch((err) => console.log(err.message));

    this.store
      .getCalendar(calendarFormData, false)
      .then(() => {
        // this.firstNews = response[0]
      })
      .catch((err) => console.log(err.message));
  },
};
</script>
