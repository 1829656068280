<template>
    <div class="row">
       <div class="col-12 mb-4">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0">Chart Patterns</h3>
              <div class="selectDark">
                <select class="form-control form-select">
                  <option>Emerging Patterns</option>
                  <option>Emerging Patterns1</option>
                  <option>Emerging Patterns2</option>
                  <option>Emerging Patterns3</option>
                </select>
              </div>
            </div>
        </div>
        <div class="col-12 col-xl-6">
            <div class="chartPattern">
                <ul class="tableStructure">
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart3.png" class="me-2" alt="Icon">
                        EUR/USD
                      </span>
                      <span>1,20379</span>
                      <span class="green">1,20366</span>
                      <span>1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart2.png" class="me-2" alt="Icon">
                        XAU/USD
                      </span>
                      <span class="green">1,20379</span>
                      <span>1,20366</span>
                      <span class="green">1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                    <li class="d-flex align-items-center justify-content-between active">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart1.png" class="me-2" alt="Icon">
                        GBP/CHF
                      </span>
                      <span class="red">1,20379</span>
                      <span>1,20366</span>
                      <span class="red">-1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart2.png" class="me-2" alt="Icon">
                        XAU/USD
                      </span>
                      <span>1,20379</span>
                      <span class="green">1,20366</span>
                      <span class="green">1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart3.png" class="me-2" alt="Icon">
                        GBP/CHF
                      </span>
                      <span>1,20379</span>
                      <span class="red">1,20366</span>
                      <span class="green">1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                    <li class="d-flex align-items-center justify-content-between">
                      <span class="d-flex align-items-center">
                        <img src="assets/images/chart1.png" class="me-2" alt="Icon">
                        EUR/USD
                      </span>
                      <span class="green">1,20379</span>
                      <span class="red">1,20366</span>
                      <span class="red">1.27%</span>
                      <span><img src="assets/images/bar-graph.png" class="" alt="Icon"></span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 col-xl-6">
            <ul class="emergingPatterns d-flex align-items-center">
               <li>
                 <p class="mb-0">Pattern</p>
                 <p class="mb-0 medium">Falling Wedge</p>
               </li>
               <li>
                 <p class="mb-0">Time Interval</p>
                 <p class="mb-0 medium">4 Hrs</p>
               </li>
               <li>
                 <p class="mb-0">Direction</p>
                 <p class="mb-0 medium">UP</p>
               </li>
            </ul>
            <div class="emerging-wrap bodyBG p-3 mb-2">
              <img src="assets/images/emerging1.jpg" alt="Images" class="w-100">
            </div>
            <p>Channel Up identified at 7/29 11:45. This pattern is still in the process of forming. Possible bearish price movement towards the support 129.8970 within the next 8 hours.</p>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
}
</script>